// components/ui/HapinessCard.tsx
import Image from "next/image";
import { useCartActions } from "@/hooks/useCartActions";
import { Voucher } from "@/store/slices/promotionsAndVoucher";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "@/store/store";
import { OrderItem } from "@/types/next";
import { cn, findItemByKeyValue, formatCurrency } from "@/lib/utils";
import Link from "next/link";
import { useSession } from "next-auth/react";
import { openPopup } from "@/store/slices/auth/signPopupSlice";

interface HapinessCardProps {
    voucher: Voucher;
    className?: string;
}

const HapinessCard = ({ voucher, className }: HapinessCardProps) => {
    const { increment, decrement } = useCartActions();
    const dispatch = useDispatch<AppDispatch>();
    const { data: session } = useSession();

    const { data: cartData } = useSelector((state: AppState) => state.happinessCart);

    const findInCart = (ProductId: number) => {
        if (cartData && cartData?.order_items?.length > 0)
            return findItemByKeyValue(cartData?.order_items, 'product_id', ProductId);
    }
    const formattedUrl = `/vouchers/${voucher?.voucher_url.replace("https://api.barbequenation.com/vouchers/", "")}`;

    return (
        <div className={cn("grid grid-cols-1 sm:grid-cols-2 items-center md:gap-6 gap-3 rounded-lg md:p-3 p-1.5 border border-gray-2 shadow-[12px_12px_24px_0px_#0000000A]", className)}>
            <Link href={formattedUrl}>
                <Image
                    src={`${voucher?.image}`}
                    width={569}
                    height={182}
                    alt="Voucher Image"
                    className="rounded-lg md:rounded-xl block h-full object-cover object-right max-w-full w-full"
                />
            </Link>
            <div>
                <h3 className="md:text-xl md:leading-7 font-inter text-wireframe-heading lg:mb-3 mb-2 font-bold md:font-extrabold">
                    {voucher?.voucher_name}
                    <Link href={formattedUrl} className="opacity-70 text-sm font-semibold whitespace-nowrap ml-1 underline">View Detail</Link>
                </h3>
                <div className="flex items-center gap-2 justify-between flex-wrap mt-2 md:mt-6">
                    <p className="md:text-[24px] text-[20px] leading-6 font-poppins font-semibold text-gray-5">
                        ₹{voucher?.price.number}
                    </p>
                    {(cartData && cartData?.order_items?.some((item: OrderItem) => item.product_id === voucher.voucher_id)) ? (
                        <div className="flex items-center justify-evenly space-x-2 border border-orange-500 rounded-lg w-full lg:w-20 p-[7px] md:p-2">
                            <button
                                onClick={(e) => {
                                    e.stopPropagation(); if (!session) {
                                        dispatch(openPopup(true));
                                        return;
                                    }
                                    decrement(voucher?.voucher_id)
                                }}
                                className="text-primary-5 font-inter font-normal leading-[22.4px] text-base"
                            >
                                –
                            </button>
                            <span className="text-primary-5 font-inter font-normal leading-[22.4px] text-base">
                                {findInCart(voucher.voucher_id)?.quantity}
                            </span>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation(); if (!session) {
                                        dispatch(openPopup(true));
                                        return;
                                    } increment(voucher?.voucher_id)
                                }}
                                className="text-primary-5 font-inter font-normal leading-[22.4px] text-base"
                            >
                                +
                            </button>
                        </div>
                    ) : (
                        <button
                            id={`voucher-btn-${voucher?.voucher_id}`}
                            onClick={(e) => {
                                e.stopPropagation();

                                if (!session) {
                                    dispatch(openPopup(true));
                                    return;
                                }
                                increment(voucher?.voucher_id)
                            }}

                            className="justify-center font-inter px-3 py-2 text-base font-normal rounded-lg border duration-300 leading-[22.4px] bg-primary-5 text-white border-primary-5 hover:bg-transparent hover:text-primary-5 hover:shadow-shadow-btn flex items-center group max-[767px]:py-[7px] lg:w-auto w-full min-w-20 min-h-10">
                            <span> {'ADD'}</span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HapinessCard;


