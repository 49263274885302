// hooks/useCartActions.ts
import { useEffect, useState } from "react";
import useFetch from "@/hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "@/store/store";
import { fetchDeleteHappinessCart, fetchHappinessCart } from "@/store/slices/happiness-card/happinessCartSlice";
import { useSession } from "next-auth/react";
import { toast } from "react-toastify";

export const useCartActions = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { data: session } = useSession();
    const { data, fetchData } = useFetch<any>();
    const { data: cartData } = useSelector((state: AppState) => state.happinessCart);

    useEffect(() => {
        if (data) {
            if (!session) {
                if (window) {
                    localStorage.setItem("orderId", data?.order_id);
                }
            }
            dispatch(fetchHappinessCart({ session: session ? true : false }));
        }
    }, [data, dispatch, session]);

    const increment = async (productId: number) => {
        let method = "POST";
        let body: Record<string, any> = {};

        const existingItem =
            cartData &&
            "order_items" in cartData &&
            cartData?.order_items.find((item) => item.product_id === productId);

        if (existingItem) {
            method = "PUT";
            body = {
                order_item_id: existingItem.order_item_id,
                quantity: existingItem.quantity + 1,
            };
        } else {
            body = {
                product_id: productId,
                quantity: 1,
            };
        }

        await fetchData("/cart", {
            method,
            body: JSON.stringify({
                ...body,
                ...(cartData?.order_id && { order_id: cartData.order_id }),
                ...(!session && { order_id: window?.localStorage.getItem("orderId") }),
            }),
        });

        // Show toast only if the item is added for the first time
        if (!existingItem) {
            toast.success("Added to cart! Quantity: 1");
        }
    };

    const decrement = async (productId: number) => {
        if (cartData && cartData?.order_items?.length > 0) {
            const existingItem = cartData?.order_items.find((item) => item.product_id === productId);
            if (existingItem) {
                const newQuantity = existingItem.quantity - 1;

                if (newQuantity > 0) {
                    await fetchData("/cart", {
                        method: "PUT",
                        body: JSON.stringify({
                            order_item_id: existingItem.order_item_id,
                            quantity: newQuantity,
                            ...(cartData?.order_id && { order_id: cartData?.order_id }),
                            ...(!session && { order_id: localStorage.getItem("orderId") }),
                        }),
                    });
                } else {

                dispatch(fetchDeleteHappinessCart({ session: session ? true : false , url :`${process.env.NEXT_PUBLIC_SITE_URL}/api/v1/cart/${cartData?.order_id}/${existingItem.order_item_id}` }));
                    toast.warn("Removed from cart");
                }
            }
        }
    };

    return { increment, decrement };
};

